//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';

export default {
  name: 'confirm',
  data() {
    return {
      isLoading: false,
      isVerified: false,
      errorMessage: '',
    };
  },
  methods: {
    ...mapActions({
      verifyUserEmail: 'verifyUserEmail',
    }),
    toLogin() {
      try {
        this.$router.push('/Auth/login').catch(() => {});
      } catch (err) {
        console.log('toLogin err ', err);
      }
    },
    confirmVerification(data) {
      const that = this;
      that.isLoading = true;
      that.verifyUserEmail(data).then((res) => {
        that.isVerified = true;
      }).catch((err) => {
        that.errorMessage = err.errmsg;
        that.isVerified = false;
      }).finally(() => {
        that.isLoading = false;
      });
    },
    isValid(value) {
      if (value === undefined || value === null || (typeof value === 'object' && Object.keys(value).length === 0) || (typeof value === 'string' && value.trim().length === 0)) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    const that = this;
    const { uid } = this.$route.query;
    const { token } = this.$route.query;
    if (that.isValid(uid) && that.isValid(token)) {
      this.confirmVerification({
        uid,
        token,
      });
    } else {
      that.$router.push({
        name: 'login',
      }).catch(() => {});
    }
  },
};
